import { useState, useRef } from "react"
import { getDataCustomers } from "api/data_customers"
import { useNavigate } from "react-router-dom"
import { setQueryParams, getInitialParams } from "utils/query"
import { formatUTCDate } from "utils/format"

import { Badge, Button } from "react-bootstrap"
import SearchInput from "components/forms/SearchInput"
import Modal from "components/Modal"
import Header from "components/Header"
import Icon from "components/Icon"
import AgGrid from "components/ag_grid/AgGrid"
import NewDataCustomer from "components/data_customers/New"
import EditDataCustomer from "components/data_customers/Edit"

const DataCustomersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [showNew, setShowNew] = useState(false)
  const [newCustomers, setNewCustomers] = useState([])
  const [showEdit, setShowEdit] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      hide: true,
      pinned: "left",
      filter: "agNumberColumnFilter",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 280,
    },
    {
      field: "type",
      headerName: "Type",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.type === "DataCustomers::Fixed" ? "Fixed" : "Unlimited (AYCE)",
    },
    {
      field: "active",
      headerName: "Active",
      filter: "agSetColumnFilter",
      headerClass: "ag-center-aligned",
      cellClass: "ag-center-aligned-cell",
      width: 120,
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) => {
        const variant = data.active ? "success" : "danger"
        const text = data.active ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
    },
    {
      field: "signed_at",
      headerName: "Signed",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => formatUTCDate(data.signed_at),
      width: 150,
    },
    {
      field: "active_companies_count",
      headerName: "Companies",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: ({ data }) =>
        data.active_companies_count || String.fromCharCode(8212),
    },
    {
      field: "max_swaps",
      headerName: "Max Swaps",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: ({ data }) => data.max_swaps || String.fromCharCode(8212),
    },
    {
      field: "swaps_count",
      headerName: "Swaps Count",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: ({ data }) => data.swaps_count || String.fromCharCode(8212),
    },
    {
      field: "exclude_sr_mgmt",
      headerName: "Exclude SR Mgmt",
      filter: "agSetColumnFilter",
      headerClass: "ag-center-aligned",
      cellClass: "ag-center-aligned-cell",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) => {
        const variant = data.exclude_sr_mgmt ? "success" : "danger"
        const text = data.exclude_sr_mgmt ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
    },
    {
      field: "headcounts_active",
      headerName: "Headcounts",
      filter: "agSetColumnFilter",
      headerClass: "ag-center-aligned",
      cellClass: "ag-center-aligned-cell",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) => {
        const variant = data.headcounts_active ? "success" : "danger"
        const text = data.headcounts_active ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
    },
    {
      field: "qualitative_data_active",
      headerName: "Qualitative Data",
      filter: "agSetColumnFilter",
      headerClass: "ag-center-aligned",
      cellClass: "ag-center-aligned-cell",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) => {
        const variant = data.qualitative_data_active ? "success" : "danger"
        const text = data.qualitative_data_active ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
    },
    {
      field: "deliver_method",
      headerName: "Delivery Method",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Email", "S3"],
      },
    },
    {
      field: "delivery_frequency",
      headerName: "Delivery Frequency",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Monthly", "Twice Monthly"],
      },
    },
    {
      field: "actions",
      headerName: "Edit",
      filter: false,
      sortable: false,
      suppressMenu: true,
      headerClass: "ag-center-aligned",
      cellClass: "justify-content-center",
      width: 80,
      cellRenderer: ({ data }) => (
        <button className="btn" onClick={() => setShowEdit(data)}>
          <Icon name="edit" />
        </button>
      ),
    },
  ])

  const navigate = useNavigate()
  const gridRef = useRef()

  const onCellClicked = e => {
    navigate(`/customers/data/${e.data.id}`)
  }

  const handleSearch = q => {
    setSearch(q)
  }

  const closeModal = () => {
    setShowNew(false)
  }

  const handleSuccess = customer => {
    setNewCustomers([...newCustomers, customer])
    closeModal()
  }

  const handleEditSuccess = data => {
    setUpdatedRecord(data)
    closeModal()
  }

  const handleClose = () => {
    setShowEdit(null)
    setShowNew(false)
  }

  return (
    <>
      <Header title="Data Customers" icon="customer" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search data customers..."
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          New Data Customer
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getDataCustomers}
          watchedValues={newCustomers}
          search={search}
          updatedRecord={updatedRecord}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
      <Modal
        title={showNew ? "Create Data Customer" : "Edit Data Customer"}
        show={showNew || showEdit}
        handleClose={handleClose}
      >
        {showNew && (
          <NewDataCustomer
            handleSuccess={handleSuccess}
            handleClose={closeModal}
          />
        )}
        {showEdit && (
          <EditDataCustomer
            customer={showEdit}
            handleSuccess={handleEditSuccess}
            handleClose={handleClose}
          />
        )}
      </Modal>
    </>
  )
}

export default DataCustomersRoute
