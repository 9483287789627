import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

export const formatDateTime = date => {
  return date
    ? dayjs(date).format("MMM D, YYYY h:mm A")
    : String.fromCharCode(8212)
}

export const formatDate = date => {
  return date ? dayjs(date).format("MMM D, YYYY") : String.fromCharCode(8212)
}

export const formatDateMonth = date => {
  dayjs.extend(utc)
  return date ? dayjs.utc(date).format("MMM YYYY") : String.fromCharCode(8212)
}

export const formatUTCDate = date => {
  dayjs.extend(utc)
  return date
    ? dayjs.utc(date).format("MMM D, YYYY")
    : String.fromCharCode(8212)
}

export const formatInputDateTime = date => {
  return date
    ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
    : String.fromCharCode(8212)
}

export const formatLocal = datetime => {
  return dayjs(datetime).format("YYYY-MM-DDTHH:mm:ssZ")
}

export const formatCurrency = (dollar, currency = "USD") => {
  if (dollar == null) return String.fromCharCode(8212) // checks for null or undefined

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
  })

  return currencyFormatter.format(dollar)
}

export const cleanTrim = (string, maxLength = 145, rough = false) => {
  if (string) {
    const roughTrim = string.substr(0, maxLength)

    if (rough) {
      return string.length < maxLength
        ? string
        : roughTrim.substr(0, maxLength) + "..."
    }

    const trimmedDescription = roughTrim.substr(
      0,
      Math.min(roughTrim.length, roughTrim.lastIndexOf(" "))
    )
    return string.length < maxLength ? string : trimmedDescription + "..."
  }
  return ""
}

export const htmlStripper = html => {
  return html.replace(/(\\r\\n|\\n|\\r\|)|(<iframe.*?\/iframe>)/gm, "")
}
