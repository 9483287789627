import { Link } from "react-router-dom"

function GridJob({ job }) {
  if (!job) return String.fromCharCode(8212)

  const { id, title } = job

  return (
    <div className="grid-company">
      <Link to={`/jobs/${id}`}>{title}</Link>
    </div>
  )
}

export default GridJob
