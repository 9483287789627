import { useState, useRef } from "react"
import { getJobs } from "api/jobs"
import { useNavigate } from "react-router-dom"
import { AgGridDateTime, AgGridHyperlink } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"
import { Link } from "react-router-dom"
import { updateJob } from "api/jobs"
import toast from "react-hot-toast"

import Badge from "react-bootstrap/Badge"

import Header from "components/Header"
import StatusBadge from "components/jobs/StatusBadge"
import SearchInput from "components/forms/SearchInput"
import AgGrid from "components/ag_grid/AgGrid"

const JobsRoute = () => {
  const navigate = useNavigate()
  const gridRef = useRef()

  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [columnDefs] = useState([
    {
      field: "jobs.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => <div>{data.id}</div>,
      hide: true,
      pinned: "left",
      width: 120,
    },
    {
      field: "jobs.title",
      headerName: "Title",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <span className="d-flex align-items-center justify-content-between">
            <span>{data.title}</span>
            {data.featured && (
              <Badge pill bg="info" text="dark">
                Featured
              </Badge>
            )}
          </span>
        )
      },
      pinned: "left",
      width: 410,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
      ),
    },
    {
      field: "customers.access_type",
      headerName: "Tier",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Full Platform", "Profile Only", "Free"],
      },
      cellRenderer: ({ data }) => data.customer?.access_type || "Seeded",
    },
    {
      field: "external_integration_identifiers.identifier.job_post_id",
      headerName: "Partner Job Post ID",
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) =>
        data.external_id?.identifier?.job_post_id || String.fromCharCode(8212),
    },
    {
      field: "external_integration_identifiers.identifier.location",
      headerName: "Partner Location",
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) =>
        data.external_id?.identifier?.location || String.fromCharCode(8212),
    },
    {
      field: "jobs.location_type",
      headerName: "Location Type",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Fully remote",
          "Remote in territory",
          "In office / full time",
          "In office / hybrid",
        ],
      },
      cellRenderer: ({ data }) =>
        data.location_type || String.fromCharCode(8212),
    },
    {
      field: "roles.name",
      headerName: "Role",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <div>{data?.role?.name || String.fromCharCode(8212)}</div>
      ),
      width: 200,
    },
    {
      field: "job_applications_count",
      headerName: "Applications",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        data.external_url
          ? String.fromCharCode(8212)
          : data.job_applications_count,
    },
    {
      field: "external_url",
      headerName: "External URL",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return data?.external_url
          ? AgGridHyperlink(data?.external_url, true)
          : String.fromCharCode(8212)
      },
      externalURL: true,
    },
    {
      field: "jobs.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Draft", "Active", "Closed", "Pending approval"],
      },
      cellRenderer: ({ data }) => (
        <StatusBadge pill={true} status={data.status} />
      ),
    },
    {
      field: "jobs.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "jobs.approved_at",
      headerName: "Approved",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.approved_at),
      hide: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClass: "ag-center-aligned",
      headerClass: "ag-center-aligned",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) => (
        <>
          {data.status !== "Active" && data.status !== "Ignored" ? (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => ignoreJob(data.id)}
            >
              Ignore
            </button>
          ) : (
            String.fromCharCode(8212)
          )}
        </>
      ),
    },
  ])

  const handleSearch = q => {
    setSearch(q)
  }

  const ignoreJob = id => {
    const params = { status: "ignored" }
    updateJob(id, params)
      .then(response => {
        setUpdatedRecord(response.data)
        toast.success("Job set to 'Ignored'")
      })
      .catch()
  }

  const onCellClicked = e => {
    navigate(`/jobs/${e.data.id}`)
  }

  return (
    <>
      <Header title="Job Posts" icon="job" className="has-search">
        <SearchInput
          value={search}
          placeholder={"Search jobs..."}
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getJobs}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
        />
      </div>
    </>
  )
}

export default JobsRoute
