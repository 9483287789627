import React from "react"

import Tabs from "components/Tabs"
import Header from "components/Header"
import Profiles from "routes/candidate/Profiles"
import Connections from "routes/candidate/Connections"

const CandidateRoute = () => {
  const tabs = [
    {
      label: "Profiles",
      hash: "#profiles",
      component: <Profiles />,
    },
    {
      label: "Connections",
      hash: "#connections",
      component: <Connections />,
    },
  ]

  return (
    <>
      <Header title="Candidate Sourcing" icon="candidateProfiles" />
      <div className="candidate content">
        <Tabs tabs={tabs} defaultTab="profiles" />
      </div>
    </>
  )
}

export default CandidateRoute
