import { useEffect, useState, useMemo } from "react"
import {
  getFinancialMetrics,
  deleteFinancialMetric,
  createFinancialMetric,
} from "api/companies/financial_metrics"
import toast from "react-hot-toast"
import { useForm } from "react-hook-form"
import { formatCurrency } from "utils/format"

import Modal from "components/Modal"
import Icon from "components/Icon"
import NewFinancialMetric from "components/companies/financial_metrics/New"
import EditFinancialMetric from "components/companies/financial_metrics/Edit"

import "./FinancialMetrics.scss"

const CompanyFinancialMetricsRoute = ({ id }) => {
  const [financialMetrics, setFinancialMetrics] = useState([])
  const [loading, setLoading] = useState(true)
  const [showEdit, setShowEdit] = useState(false)
  const [editing, setEditing] = useState({})
  const [errors, setErrors] = useState([])

  const loadFinancialMetrics = id => {
    getFinancialMetrics(id)
      .then(response => {
        setFinancialMetrics(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const handleEdit = (e, id) => {
    e.preventDefault()
    const metric = financialMetrics.find(metric => metric.id === id)
    setErrors([])
    setEditing(metric)
    setShowEdit(true)
  }

  const handleDelete = (e, id) => {
    e.preventDefault()
    setErrors([])
    const metric = financialMetrics.find(metric => metric.id === id)
    if (
      window.confirm(
        `Are you sure you want to delete the ${metric.fiscal_quarter} ${metric.year} financial metric?`
      )
    ) {
      deleteFinancialMetric(id).then(() => {
        setFinancialMetrics(financialMetrics.filter(metric => metric.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
  }

  const replaceFinancialMetric = financialMetric => {
    const newFinancialMetrics = [...financialMetrics]
    const index = newFinancialMetrics.findIndex(
      h => h.id === financialMetric.id
    )
    newFinancialMetrics[index] = financialMetric
    setFinancialMetrics(newFinancialMetrics)
  }

  const { handleSubmit, register, reset, control } = useForm()

  const defaultValues = useMemo(
    () => ({
      year: null,
      fiscal_quarter: null,
      revenue: null,
      yearly_growth: null,
    }),
    []
  )

  const onSubmit = financialMetric => {
    return createFinancialMetric(id, financialMetric)
      .then(() => {
        toast.success("Create successful")
        reset()
        setErrors([])
        loadFinancialMetrics(id)
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  useEffect(() => {
    loadFinancialMetrics(id)
  }, [id])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  if (loading) return <div />

  return (
    <div className="company-financial-metrics">
      <div className="tab-header">
        <h3>Financial Metrics</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="table table-hover editable-table">
          <thead>
            <tr>
              <th>Year</th>
              <th>Fiscal Quarter</th>
              <th>Type</th>
              <th className="text-end">Revenue</th>
              <th className="text-end">Yearly Growth</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <NewFinancialMetric
              register={register}
              errors={errors}
              control={control}
            />
            {financialMetrics &&
              financialMetrics.map(financialMetrics => {
                const {
                  id,
                  fiscal_quarter,
                  year,
                  revenue,
                  yearly_growth,
                  type,
                } = financialMetrics
                return (
                  <tr key={id}>
                    <td className="field-value">{year}</td>
                    <td className="field-value">{fiscal_quarter}</td>
                    <td className="field-value">{type}</td>
                    <td className="field-value text-end">
                      {revenue
                        ? formatCurrency(revenue)
                        : String.fromCharCode(8212)}
                    </td>
                    <td className="field-value text-end">
                      {yearly_growth === null
                        ? String.fromCharCode(8212)
                        : `${yearly_growth}%`}
                    </td>
                    <td className="actions">
                      <button
                        className="btn me-1"
                        onClick={e => handleEdit(e, id)}
                      >
                        <Icon name="edit" />
                      </button>
                      <button
                        className="btn"
                        onClick={e => handleDelete(e, id)}
                      >
                        <Icon name="delete" />
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </form>
      <Modal
        title={"Edit Financial Metric"}
        show={showEdit}
        handleClose={closeModal}
      >
        {showEdit && (
          <EditFinancialMetric
            financial_metric={editing}
            handleSuccess={replaceFinancialMetric}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </div>
  )
}

export default CompanyFinancialMetricsRoute
