import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getCandidateConnections } from "api/candidate/connections"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"

import Icon from "components/Icon"
import GridUser from "components/users/GridUser"
import GridCompany from "components/companies/GridCompany"
import GridJob from "components/jobs/GridJob"
import AgGrid from "components/ag_grid/AgGrid"
import CandidateProfileDetail from "components/candidate/Detail"

import "./Candidate.scss"

const CandidatConnectionsRoute = () => {
  const gridRef = useRef()
  const { id } = useParams()
  const navigate = useNavigate()
  const initialParams = getInitialParams()
  const [showDetail, setShowDetail] = useState(undefined)

  const [columnDefs] = useState([
    {
      field: "candidate_connections.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => data.id,
    },
    {
      field: "users.name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
      width: 140,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridCompany company={data.company} />,
      width: 120,
    },
    {
      field: "jobs.title",
      headerName: "Job",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridJob job={data.job} />,
    },
    {
      field: "employers.name",
      headerName: "Employer",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data?.employer.name || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "candidate_connections.meeting_scheduled",
      headerName: "Meeting",
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return data.meeting_scheduled ? (
          <Icon name="check" />
        ) : (
          String.fromCharCode(8212)
        )
      },
      width: 100,
    },
    {
      field: "candidate_connections.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 140,
    },
  ])

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate(`/candidate#profiles`)
  }

  const onCellClicked = e => {
    navigate(`/candidate/${e.data?.profile?.id}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <div className="candidate-header">
        <h3>Connections</h3>
      </div>
      <div className="candidate-grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getCandidateConnections}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          sizeToFit={true}
        />
      </div>
      <CandidateProfileDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
      />
    </>
  )
}

export default CandidatConnectionsRoute
