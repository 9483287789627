import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getReviewApprovals } from "api/approvals"
import { Link } from "react-router-dom"
import { formatDateTime } from "utils/format"
import ReviewDetail from "components/reviews/Detail"

import StatusBadge from "components/reviews/StatusBadge"
import Header from "components/Header"

import "./Table.scss"

const ReviewApprovalsRoute = () => {
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDetail, setShowDetail] = useState(undefined)
  const [approvedReview, setApprovedReview] = useState(null)

  const { id } = useParams()
  const navigate = useNavigate()

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate("/approvals/reviews")
  }

  const handleGridUpdate = data => {
    setApprovedReview(data)
  }

  useEffect(() => {
    getReviewApprovals()
      .then(response => {
        setReviews(response.data.approvables)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [approvedReview])

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  if (loading) {
    return <div />
  }

  return (
    <>
      <Header title="My Pending Review Approvals" icon="review" />
      <div className="approvals-table">
        {reviews.length > 0 ? (
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {reviews.map(review => {
                const { approvable } = review
                const { id, status, created_at } = approvable

                return (
                  <tr key={id}>
                    <td>
                      <Link to={`/approvals/reviews/${id}`}>{id}</Link>
                    </td>
                    <td>
                      <StatusBadge status={status} />
                    </td>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <em>You have no pending review approvals.</em>
        )}
      </div>
      <ReviewDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setUpdatedRecord={handleGridUpdate}
      />
    </>
  )
}

export default ReviewApprovalsRoute
