/*
 * Convert milliseconds to a time string
 */
export function msToTime(ms) {
  const seconds = Math.floor((ms / 1000) % 60)
  const minutes = Math.floor((ms / (1000 * 60)) % 60)
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
  const days = Math.floor(ms / (1000 * 60 * 60 * 24))

  // Create an array to hold the non-zero units
  const timeUnits = []
  if (days > 0) timeUnits.push(`${days}d`)
  if (hours > 0) timeUnits.push(`${hours}h`)
  if (minutes > 0) timeUnits.push(`${minutes}m`)
  if (seconds > 0) timeUnits.push(`${seconds}s`)

  // Join the non-zero units with a space and return as a single string
  return timeUnits.join(" ")
}
