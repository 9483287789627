import { Link } from "react-router-dom"

import "./Overview.scss"

const LocationOverviewRoute = ({ location }) => {
  const { id, name_with_state_code, slug, children, parent } = location
  return (
    <>
      <div className="mx-1 mt-4">
        <table className="table locations-table table-hover w-50 mb-4">
          <tbody>
            <tr>
              <th>ID</th>
              <td className="text-end">{id}</td>
            </tr>
            <tr>
              <th>Display</th>
              <td className="text-end">{name_with_state_code}</td>
            </tr>
            {parent && (
              <tr>
                <th>Parent</th>
                <td className="text-end">
                  <Link to={`/metro_locations/${parent?.id}`}>
                    {location?.parent?.name}
                  </Link>
                </td>
              </tr>
            )}
            <tr>
              <th>Slug</th>
              <td className="text-end">{slug}</td>
            </tr>
          </tbody>
        </table>
        {children?.length > 0 && (
          <>
            <h4 className="d-flex align-items-center gap-2 mb-3">
              Children
              <span className="children-count badge rounded-pill bg-primary me-2">
                {children?.length}
              </span>
            </h4>
            <table className="table assignments-table table-hover h-auto w-50">
              <thead>
                <tr className="table-header">
                  <th>ID</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {children?.map(location => {
                  const { name, id } = location

                  return (
                    <tr key={location.id}>
                      <td className="w-25">{id}</td>
                      <td>
                        <Link to={`/metro_locations/${id}`}>{name}</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  )
}

export default LocationOverviewRoute
