import { useState, useRef } from "react"
import { getCompanyJobs } from "api/companies/jobs"
import { AgGridDateTime } from "utils/cellRenderers"
import { useNavigate, Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"

import AgGrid from "components/ag_grid/AgGrid"
import StatusBadge from "components/jobs/StatusBadge"

const CompanyJobsRoute = ({ id }) => {
  const gridRef = useRef()
  const navigate = useNavigate()

  const [columnDefs] = useState([
    {
      field: "title",
      headerName: "Title",
      cellRenderer: ({ data }) => (
        <span className="d-flex align-items-center justify-content-between">
          <span>{data.title}</span>
          {data.featured && (
            <Badge pill bg="info" text="dark">
              Featured
            </Badge>
          )}
        </span>
      ),
      filter: "agTextColumnFilter",
      width: 350,
    },
    {
      field: "job_applications_count",
      headerName: "Applications",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        data.external_url
          ? String.fromCharCode(8212)
          : data.job_applications_count,
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Draft", "Active", "Closed", "Pending approval"],
      },
      cellRenderer: ({ data }) => (
        <StatusBadge pill={true} status={data.status} />
      ),
      width: 100,
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 150,
    },
  ])

  const onCellClicked = e => {
    navigate(`/jobs/${e.data.id}`)
  }

  const retrieveCompanyJobs = async params => {
    return getCompanyJobs(id, params)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Jobs</h3>
        <Link to="jobs/new">
          <button className="btn btn-primary">New Job</button>
        </Link>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveCompanyJobs}
        sizeToFit={true}
      />
    </>
  )
}

export default CompanyJobsRoute
