import apiCall from "utils/api"

export const getJobRoles = () => {
  return apiCall("get", `jobs/roles`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
