import { useEffect, useState } from "react"
import { getUserJobPreferences } from "api/users/job_preferences"

const JobPreferences = ({ id }) => {
  const [jobPreferences, setJobPreferences] = useState({})

  useEffect(() => {
    if (!id) return
    getUserJobPreferences(id)
      .then(response => {
        setJobPreferences(response.data)
      })
      .catch(() => {
        // setLoading(false)
      })
  }, [id])

  const {
    years_selling_experience,
    relocation_preference,
    search_status,
    job_preference_industries,
    job_preference_metro_locations,
    job_preference_roles,
  } = jobPreferences

  const rolesList = job_preference_roles
    ?.map(job_preference_role => job_preference_role.role.name)
    .join(", ")

  const industriesList =
    job_preference_industries &&
    job_preference_industries.map(job_preference_industry => {
      const { industry } = job_preference_industry
      return (
        <span key={industry.id} className="badge rounded-pill bg-primary me-2">
          {industry.name}
        </span>
      )
    })

  const metroLocationsList =
    job_preference_metro_locations &&
    job_preference_metro_locations.map(job_preference_metro_location => {
      const { metro_location } = job_preference_metro_location
      return (
        <span
          key={metro_location.id}
          className="badge rounded-pill bg-primary me-2"
        >
          {metro_location.name_with_state_code}
        </span>
      )
    })

  return (
    <div className="job_preferences">
      <h4>Job Preferences</h4>
      <div className="editable-table">
        <table className="table horizontal">
          <tbody>
            <tr>
              <th>Role(s)</th>
              <td>{rolesList}</td>
            </tr>
            <tr>
              <th>Years Experience</th>
              <td>{years_selling_experience}</td>
            </tr>
            <tr>
              <th>Search Status</th>
              <td>{search_status}</td>
            </tr>
            <tr>
              <th>Relocation Preference</th>
              <td>{relocation_preference}</td>
            </tr>
            <tr>
              <th>Industries</th>
              <td>{industriesList}</td>
            </tr>
            <tr>
              <th>Location(s)</th>
              <td>{metroLocationsList}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default JobPreferences
