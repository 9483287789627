import { useState, useRef } from "react"
import { getCompanies } from "api/companies"
import { getIndustries } from "api/industries"
import { useNavigate } from "react-router-dom"
import {
  AgGridHyperlink,
  AgGridDateTime,
  AgGridDate,
} from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"
import { formatDateMonth, formatUTCDate } from "utils/format"
import { filterParams } from "utils/filters"

import { Badge, Button } from "react-bootstrap"

import Header from "components/Header"
import Icon from "components/Icon"
import SearchInput from "components/forms/SearchInput"
import AgGrid from "components/ag_grid/AgGrid"
import Modal from "components/Modal"
import NewCompany from "components/companies/New"

import "./Companies.scss"

const CompaniesRoute = () => {
  const navigate = useNavigate()
  const gridRef = useRef()

  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""
  const [search, setSearch] = useState(initialSearch)
  const [showNew, setShowNew] = useState(false)
  const [newCompanies, setNewCompanies] = useState([])

  const [columnDefs] = useState([
    {
      field: "companies.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      pinned: "left",
      width: 120,
    },
    {
      field: "companies.name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.name,
      pinned: "left",
    },
    {
      field: "industries.name",
      headerName: "Industry",
      filter: "agSetColumnFilter",
      filterParams: {
        values: params => {
          getIndustries().then(response =>
            params.success(
              response.data.industries.map(industry => industry.name)
            )
          )
        },
      },
      cellRenderer: ({ data }) => data.industry || String.fromCharCode(8212),
    },
    {
      field: "companies.website",
      headerName: "Website",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) => AgGridHyperlink(data.website, true),
      externalURL: true,
    },
    {
      field: "companies.linkedin_url",
      headerName: "LinkedIn",
      hide: true,
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => AgGridHyperlink(data.linkedin_url, true),
    },
    {
      field: "companies.ratings_count",
      headerName: "Ratings Count",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.ratings_count,
    },
    {
      field: "companies.distinct_raters_count",
      headerName: "Distinct Raters",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.distinct_raters_count,
    },
    {
      field: "companies.verified_ratings_percent",
      headerName: "Verified Rating %",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        data.verified_ratings_percent.toFixed(0) || String.fromCharCode(8212),
    },
    {
      field: "companies.verified_ratings_count",
      headerName: "Verified Ratings",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.verified_ratings_count,
    },
    {
      field: "companies.eligible_ratings_count",
      headerName: "Eligible Ratings",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.eligible_ratings_count,
    },
    {
      field: "companies.published_reviews_count",
      headerName: "Published Reviews",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.published_reviews_count,
    },
    {
      field: "companies.repvue_score",
      headerName: "RepVue Score",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.repvue_score.toFixed(2),
    },
    {
      field: "headcounts.date",
      headerName: "Last Headcount",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => formatDateMonth(data.last_headcount),
    },
    {
      field: "parents_companies.name", // for rails magic
      headerName: "Parent",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: ({ data }) =>
        data.parent?.name || String.fromCharCode(8212),
    },
    {
      field: "companies.published_at",
      headerName: "Published",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      cellRenderer: ({ data }) => {
        const variant = data.published ? "success" : "danger"
        const text = data.published ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
      sortable: false,
    },
    {
      field: "companies.published_status",
      headerName: "Published Status",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.published_status || String.fromCharCode(8212),
      sortable: false,
    },
    {
      field: "companies.funding_source",
      headerName: "Funding Source",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Acquired",
          "Affiliate Org",
          "Bootstrapped",
          "Public",
          "Private Equity",
          "Unspecified",
          "Venture Capital",
        ],
      },
      cellRenderer: ({ data }) =>
        data.funding_source || String.fromCharCode(8212),
    },
    {
      field: "companies.size",
      headerName: "Size",
      cellClass: "ag-right-aligned-cell",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["1-50", "51-250", "251-1,000", "1,001-10,000", "10,001+"],
      },
      cellRenderer: ({ data }) => data.size || String.fromCharCode(8212),
    },
    {
      field: "companies.tags_count",
      headerName: "Tags Count",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.tags_count,
    },
    {
      field: "companies.reviewed_at",
      headerName: "Last Review",
      filter: "agDateColumnFilter",
      hide: true,
      cellRenderer: ({ data }) =>
        data.reviewed_at
          ? AgGridDate(data.reviewed_at)
          : String.fromCharCode(8212),
    },
    {
      field: "companies.next_review_date",
      headerName: "Next Review",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) =>
        data.next_review_date
          ? formatUTCDate(data.next_review_date)
          : String.fromCharCode(8212),
    },
    {
      field: "companies.icp_flag",
      headerName: "ICP Flag",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) =>
        data.icp_flag ? <Icon name="flag" /> : String.fromCharCode(8212),
    },
    {
      field: "companies.flagged_for_question",
      headerName: "Flagged",
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      sortable: false,
      width: 140,
      cellRenderer: ({ data }) => {
        return data.flagged_for_question ? (
          <Icon name="questionFlag" />
        ) : (
          String.fromCharCode(8212)
        )
      },
    },
    {
      field: "companies.publish_on_hold",
      headerName: "Publish On Hold",
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) => (data.publish_on_hold ? "True" : "False"),
    },
    {
      field: "companies.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/companies/${e.data.id}`)
  }

  const closeModal = () => {
    setShowNew(false)
  }

  const handleSuccess = company => {
    setNewCompanies([...newCompanies, company])
    closeModal()
  }

  return (
    <>
      <Header title="Companies" icon="company" className="has-search">
        <SearchInput
          value={search}
          placeholder={"Search companies..."}
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          New Company
        </Button>
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getCompanies}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          watchedValues={newCompanies}
        />
      </div>
      <Modal title="Create Company" show={showNew} handleClose={closeModal}>
        <NewCompany
          handleSuccess={handleSuccess}
          handleClose={() => setShowNew(false)}
        />
      </Modal>
    </>
  )
}

export default CompaniesRoute
