import React, { useEffect, useState } from "react"
import { getCompanyLocations, deleteLocation } from "api/companies/locations"
import toast from "react-hot-toast"
import Button from "react-bootstrap/Button"

import Modal from "components/Modal"
import Icon from "components/Icon"
import EditLocation from "components/companies/locations/Edit"
import NewLocation from "components/companies/locations/New"

import "./Locations.scss"

const CompanyLocationsRoute = ({ id }) => {
  const [locations, setLocations] = useState([])
  const [showEdit, seShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [editing, setEditing] = useState({})
  const [loading, setLoading] = useState(true)

  const loadLocations = id => {
    getCompanyLocations(id)
      .then(response => {
        setLocations(response.data.locations)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadLocations(id)
  }, [id])

  const handleEdit = id => {
    const location = locations.find(location => location.id === id)
    setEditing(location)
    seShowEdit(true)
  }

  const handleDelete = id => {
    const location = locations.find(location => location.id === id)
    if (
      window.confirm(
        `Are you sure you want to delete ${location.metro_location.name}?`
      )
    ) {
      deleteLocation(id).then(() => {
        setLocations(locations.filter(location => location.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    seShowEdit(false)
    setShowNew(false)
  }

  // use this to update the location in state as opposed to refreshing all or using redux
  const replaceLocation = location => {
    const newLocations = [...locations]
    const index = newLocations.findIndex(l => l.id === location.id)
    newLocations[index] = location
    setLocations(newLocations)
  }

  if (loading) return <div />

  return (
    <div className="company-locations">
      <div className="tab-header">
        <h3>Locations</h3>
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Add Location
        </Button>
      </div>
      <table className="table table-hover editable-table">
        <thead>
          <tr>
            <th>Metro</th>
            <th className="location-boolean">Office</th>
            <th className="location-boolean">HQ</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          {locations &&
            locations.map(location => {
              const { id, metro_location, office, hq } = location
              return (
                <tr key={id}>
                  <td>{metro_location.name_with_state_code}</td>
                  <td className="location-boolean">
                    {office ? <Icon name="check" /> : String.fromCharCode(8212)}
                  </td>
                  <td className="location-boolean">
                    {hq ? <Icon name="check" /> : String.fromCharCode(8212)}
                  </td>
                  <td className="actions">
                    <button className="btn me-1" onClick={() => handleEdit(id)}>
                      <Icon name="edit" />
                    </button>
                    <button className="btn" onClick={() => handleDelete(id)}>
                      <Icon name="delete" />
                    </button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <Modal
        title={showEdit ? "Edit Location" : "New Location"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewLocation
            companyId={id}
            handleSuccess={() => loadLocations(id)}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditLocation
            location={editing}
            handleSuccess={replaceLocation}
            handleClose={() => seShowEdit(false)}
          />
        )}
      </Modal>
    </div>
  )
}

export default CompanyLocationsRoute
