import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import { Col, Row } from "react-bootstrap"
import Input from "components/forms/Input"
import CountriesSelect from "components/countries/Select"
import StatesSelect from "components/states/Select"
import MetroLocationsSelect from "./Select"
import Select from "components/forms/Select"

const MetroLocationForm = ({ onSubmit, location, errors }) => {
  const { handleSubmit, register, reset, control } = useForm({ location })

  const defaultValues = useMemo(
    () => ({
      name: location?.name,
      type: location?.type,
      parent: location?.parent,
      country: location?.country,
      state: location?.state,
      label: location?.label,
    }),
    [location]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const types = [
    { id: "city", name: "City" },
    { id: "metro", name: "Metro" },
    { id: "state", name: "State" },
    { id: "country", name: "Country" },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <Input
          type="text"
          name="name"
          label="Name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Type
        </label>
        <Select
          name="type"
          control={control}
          options={types}
          error={getErrors("type")[0]}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="parent" className="form-label">
          Parent
        </label>
        <MetroLocationsSelect
          name="parent_id"
          control={control}
          value={defaultValues.parent}
          types={["metro", "state"]}
          error={getErrors("parent")[0]}
        />
      </div>
      <Row>
        <Col>
          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              State
            </label>
            <StatesSelect
              name="state_id"
              control={control}
              value={defaultValues.state}
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <CountriesSelect
              name="country_id"
              control={control}
              value={defaultValues.country}
            />
          </div>
        </Col>
      </Row>

      <div className="mb-3">
        <label htmlFor="label" className="form-label">
          Label
        </label>
        <Input
          type="text"
          name="label"
          label="Label"
          error={getErrors("label")[0]}
          register={register}
        />
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default MetroLocationForm
