import { useState, useEffect } from "react"
import Modal from "components/Modal"
import Badge from "react-bootstrap/Badge"
import { formatDateTime } from "utils/format"

import "./AuditLog.scss"

const AuditLogRoute = ({ id, route, getterFunction }) => {
  const [audits, setAudits] = useState([])
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [selectedAudit, setSelectedAudit] = useState({})

  useEffect(() => {
    getterFunction(id)
      .then(response => {
        setAudits(response.data.audits)
      })
      .catch(() => {
        // setLoading(false)
      })
  }, [id, route, getterFunction])

  const showDetail = id => {
    const audit = audits.find(audit => audit.id === id)
    setSelectedAudit(audit)
    setShowDetailModal(true)
  }

  const getVariant = action => {
    switch (action) {
      case "create":
        return "success"
      case "destroy":
        return "danger"
      default:
        return "primary"
    }
  }

  const formatValue = value => {
    if (typeof value == "boolean") {
      return value ? "true" : "false"
    }

    return value
  }

  return (
    <div className="company-audits">
      <div className="tab-header">
        <h3>Audit Log</h3>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>User</th>
            <th>Type</th>
            <th>Action</th>
            <th>Changes</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {audits &&
            audits.map(audit => {
              const { id, name, type, action, audited_changes, created_at } =
                audit

              return (
                <tr key={id} onClick={() => showDetail(id)}>
                  <td className="audit-user">{name}</td>
                  <td>{type}</td>
                  <td>
                    <Badge bg={getVariant(action)}>{action}</Badge>
                  </td>
                  <td className="audits">
                    {Object.keys(audited_changes).join(", ")}
                  </td>
                  <td className="audit-date">{formatDateTime(created_at)}</td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <Modal
        title="Audit Detail"
        show={showDetailModal}
        size="xl"
        handleClose={() => setShowDetailModal(false)}
      >
        {showDetailModal && (
          <div className="audit-detail">
            <div className="audit-detail-header">
              <span>{`By ${selectedAudit.name} on ${formatDateTime(
                selectedAudit.created_at
              )}`}</span>
              <Badge bg={getVariant(selectedAudit.action)}>
                {selectedAudit.action}
              </Badge>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedAudit.audited_changes).map(key => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td className="value">
                      {Array.isArray(selectedAudit.audited_changes[key])
                        ? formatValue(selectedAudit.audited_changes[key][0]) ||
                          String.fromCharCode(8212)
                        : String.fromCharCode(8212)}
                    </td>
                    <td className="value text-field">
                      {Array.isArray(selectedAudit.audited_changes[key])
                        ? formatValue(selectedAudit.audited_changes[key][1])
                        : formatValue(selectedAudit.audited_changes[key]) ||
                          String.fromCharCode(8212)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default AuditLogRoute
