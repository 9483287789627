import { useEffect, useState } from "react"
import { getJobRoles } from "api/jobs/roles"

import Select from "components/forms/Select"

const RoleSelect = ({ field, ...props }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    getJobRoles().then(response => {
      const payload = response.data
      const options = payload.map(role => ({
        id: role.id,
        name: role.name,
      }))
      setOptions(options)
    })
  }, [field])

  return <Select options={options} {...props} />
}

export default RoleSelect
