import { useState, useRef } from "react"
import { getN2SUsers, approveN2SUser } from "api/users"
import { useNavigate } from "react-router-dom"
import { AgGridHyperlink, AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"
import toast from "react-hot-toast"

import SearchInput from "components/forms/SearchInput"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"

const NewToSalesUsersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
    },
    {
      field: "linkedin_url",
      headerName: "LinkedIn",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) => AgGridHyperlink(data.linkedin_url, true),
      externalURL: true,
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.country || String.fromCharCode(8212),
    },
    {
      field: "new_to_sales_type",
      headerName: "Type",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Student", "Military Transition", "Other Transition"],
      },
      sortable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Male",
          "Female",
          "Non-conforming",
          "Not disclosed",
          "Transgender",
        ],
      },
      sortable: false,
      hide: true,
    },
    {
      field: "ethnicity",
      headerName: "Ethnicity",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "American Indian or Alaska Native",
          "Asian",
          "Black or African American",
          "Hispanic or Latino",
          "Native Hawaiian or Other Pacific Islander",
          "White",
          "Not disclosed",
        ],
      },
      sortable: false,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["New to Sales", "Pending"],
      },
      cellRenderer: ({ data }) =>
        data.status !== "Pending" ? "Approved" : data.status,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClass: "ag-center-aligned",
      headerClass: "ag-center-aligned",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) =>
        data.status === "Pending" ? (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleApprove(data.id)}
          >
            Approve
          </button>
        ) : (
          String.fromCharCode(8212)
        ),
    },
  ])

  const handleApprove = id => {
    approveN2SUser(id)
      .then(response => {
        setUpdatedRecord(response.data)
        toast.success("New to Sales user approved!")
      })
      .catch()
  }

  const navigate = useNavigate()
  const gridRef = useRef()

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/users/${e.data.id}`)
  }

  return (
    <>
      <Header title="New to Sales Users" icon="users" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search users..."
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getN2SUsers}
          search={search}
          updatedRecord={updatedRecord}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
    </>
  )
}

export default NewToSalesUsersRoute
