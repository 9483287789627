import Badge from "react-bootstrap/Badge"

import "./StatusBadge.scss"

function StatusBadge({ status }) {
  let variant
  switch (status?.toLowerCase()) {
    case "full user":
      variant = "success"
      break
    case "new to sales":
      variant = "dark"
      break
    case "deleted":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge bg={variant} className="user-status-badge">
      {status}
    </Badge>
  )
}

export default StatusBadge
